<template>
  <div>
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <router-link to="/">
            <a class="navbar-brand"><img src="../../public/WAW_Logo_Navbar.png" alt="Project: World at War Logo"/> {{ $t('nav.home') }}</a></router-link
          >
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="mr-auto nav navbar-nav"
            :class="[$store.state.locale == 'he' || $store.state.locale == 'ar' ? 'rtl-navbar' : 'ltr-navbar']">
            <li>
              <router-link style="display: none" to="/">Home</router-link>
            </li>
            <li>
              <router-link to="about">{{ $t('nav.about') }}</router-link>
            </li>
            <li>
              <router-link to="sources">{{ $t('nav.sources') }}</router-link>
            </li>
            <li>
              <router-link to="legal">{{ $t('nav.legal') }}</router-link>
            </li>
            <li>
              <a class="mobile-fullscreen" href="#" @click="openFullscreen()"><span class="glyphicon glyphicon-fullscreen"></span>&nbsp;&nbsp;<span class="text-fullscreen">{{
                $t('nav.fullscreen') }}</span></a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li :class="[$store.state.locale == 'en' ? 'dropdown-padding' : 'hidden']">
              <Dropdown class="locale-switcher p-dropdown-trigger-icon" v-model="$store.state.war" :options="conflicts"
                optionLabel="name" optionValue="value">
                <div>
                  <span class="p-dropdown-trigger-icon"><i class="pi-angle-down"></i></span>
                </div>
                <template #value>
                  <div class="lang-item">
                    <div class="text-select">{{ formatWarName(this.$store.state.war) }}</div>
                  </div>
                </template>
                <template #option="slotProps">
                  <div class="lang-dropdown-option">
                    <span class="text-dropdown" @click="reloadPage">
                      {{ slotProps.option.name }}
                    </span>
                  </div>
                </template>
              </Dropdown>
            </li>
            <li :class="[$store.state.war == '1948-arab-israeli-war' ? 'dropdown-padding' : 'hidden']">
              <Dropdown class="locale-switcher p-dropdown-trigger-icon" v-model="$i18n.locale" :options="languages"
                optionLabel="name" optionValue="value">
                <div>
                  <span class="p-dropdown-trigger-icon"><i class="pi-angle-down"></i></span>
                </div>
                <template #value="slotProps">
                  <div class="lang-item">
                    <img class="flag-select" :alt="slotProps.name"
                      :src="require(`@/assets/icons/${slotProps.value}.svg`)" />
                    <div class="text-select">{{ slotProps.value.toUpperCase() }}</div>
                  </div>
                </template>
                <template #option="slotProps">
                  <div class="lang-dropdown-option">
                    <img class="flag-dropdown" :alt="slotProps.option.name"
                      :src="require(`@/assets/icons/${slotProps.option.value}.svg`)" />
                    <span class="text-dropdown" @click="reloadPage">{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </Dropdown>
            </li>
            <li id="toggleSidebar">
              <a href="#" @click="hideSidebar()"><span class="glyphicon glyphicon-align-right"></span>&nbsp;&nbsp;{{
                $t('nav.toggle') }}</a>
            </li>
            <li>
              <a class="desktop-fullscreen" href="#" @click="openFullscreen()"><span class="glyphicon glyphicon-fullscreen"></span>&nbsp;&nbsp;<span class="text-fullscreen">{{
                $t('nav.fullscreen') }}</span></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div id="mobile-nav">
      <button id="showMap" class="mobile-nav-button" :class="$store.state.view == 'map' ? 'mobile-active' : ''"
        @click="setView('map')">
        {{ $t('nav.mobile.map') }}
      </button>
      <button id="showSidebar" class="mobile-nav-button" :class="$store.state.view == 'sidebar' ? 'mobile-active' : ''"
        @click="setView('sidebar')">
        {{ $t('nav.mobile.sidebar') }}
      </button>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n-react';

export default {
  name: 'navbar',
  data() {
    return {
      conflicts: [
        { value: '1948-arab-israeli-war', name: '1948 Arab-Israeli War' },
        { value: 'suez-crisis', name: '1956 Suez Crisis' },
      ],
      languages: [
        { value: 'en', name: 'English' },
        //{ value: 'ar', name: 'العربية' },
        { value: 'he', name: 'עברית' },
        //{ value: 'hr', name: 'Hrvatski' },
      ]
    };
  },
  computed: {
    currentConflict() {
      const conflictParam = this.$route.params.conflict;
      return this.conflicts.find(conflict => conflict.value === conflictParam) || {};
    }
  },
  watch: {
    '$i18n.locale': function () {
      // Set react i18n language based on vue i18n language selected above
      i18n.changeLanguage(this.$i18n.locale);
    },
    '$store.state.war': function () {
      window.location.reload();
    },
  },
  methods: {
    openFullscreen() {
      const elem = document.documentElement;
      if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
        else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        }
        else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      }
      else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    hideSidebar() {
      const state = document.getElementById('sidebar').style.display || 'none';
      const map = document.getElementById('mapContainer');
      const rtl = document.documentElement.getAttribute('dir') === 'rtl';
      if (state === 'none') {
        document.getElementById('sidebar').style.display = 'block';
        document.getElementById('containerSpanner').style.width = 'calc(100vw - 399px)';
        if (rtl) {
          map.style.left = '399px';
        }
        else {
          map.style.right = '399px';
        }
        this.$store.commit('resize');
      }
      else {
        document.getElementById('sidebar').style.display = 'none';
        document.getElementById('containerSpanner').style.width = '100vw';
        if (rtl) {
          map.style.left = '0px';
        }
        else {
          map.style.right = '0px';
        }
        this.$store.commit('resize');
      }
    },
    setView(state) {
      this.$store.commit('setView', state);
    },
    logSlotProps(slotProps) {
      console.log('Clicked slotProps:', slotProps);
    },
    formatWarName(warName) {
      return warName.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    reloadPage() {
      window.location.reload();
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/_global.scss';

nav {
  z-index: 999;
}

.hidden {
  display: none;
}

.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

[dir='rtl'] .navbar-brand,
[dir='rtl'] .navbar-header,
[dir='rtl'] .navbar-nav {
  float: right !important;
}

.navbar-brand {
  display: flex;
}

.navbar-brand img {
  height: 40px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -10px;
}

[dir='rtl'] .navbar-toggle,
[dir='rtl'] .navbar-right {
  float: left !important;
}

#navbar {
  background-color: $primary;
  margin: 0;
}

.navbar-default,
.navbar-brand:focus {
  color: #f5f5f5;
  background-color: #7e6951;
  border-color: #705536;
}

.navbar-default .navbar-toggle {
  background-color: #ddd;
}

.navbar-default .navbar-brand {
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
}

.navbar-default .navbar-nav>li>a {
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-brand:focus {
  color: #f5f5f5;
  outline: none;
}

.navbar-default .navbar-brand:hover {
  color: #f5f5f5;
  background-color: #a5845f;
  border-color: #a5845f;
}

nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  color: #f5f5f5;
  background-color: #a5845f;
  border-color: #a5845f;
}

.locale-switcher {
  background-color: #7e6951;
  max-height: 50px;
  padding: 0;
  border-radius: 20px;
  border: #f5f5f5 1px solid;
}

.locale-switcher:hover {
  background-color: #a5845f;
  border: #f5f5f5 1px solid;
}

.locale-switcher select {
  background-color: #7e6951;
  color: #f5f5f5;
  padding: 14px;
  border: 0;
}

.locale-switcher select:hover {
  background-color: #a5845f;
  color: #f5f5f5;
}

.navbar-toggle {
  margin: 6px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lang-dropdown-option {
  display: flex;
  align-items: center;
}

.lang-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flag-select {
  height: 2rem;
  width: 2rem;
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 100%;
}

.flag-dropdown {
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
}

.text-select {
  font-size: 1.4rem;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
}

.text-dropdown {
  font-size: 1.4rem;
  margin-right: 8px;
  margin-left: 8px;
}

.dropdown-links {
  color: #f5f5f5;
  text-decoration: none;
}

.p-dropdown .p-dropdown-trigger {
  color: #f5f5f5;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #f5f5f5;
}

.dropdown-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.navbar {
  border-radius: 0;
}

@media (max-width: 899px) {
  #toggleSidebar {
    display: none;
  }
}

@media (max-width: 1095px) and (max-height: 500px) {
  .navbar-header {
    float: none;
  }

  [dir='rtl'] .navbar-brand {
    float: right !important;
  }

  [dir='rtl'] .navbar-header {
    float: right !important;
  }

  [dir='rtl'] .navbar-toggle {
    float: right;
  }

  /* .navbar-left,
  .navbar-right {
    float: none !important;
  } */
  .navbar-toggle {
    margin-top: 6px;
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in {
    display: block !important;
  }
}

.nav-button {
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  float: left;
  padding: 15px;
  line-height: 20px;
  text-decoration: none;

  &.router-link-exact-active {
    font-weight: bold;
    background: lighten($primary, 10%);
    // color: #42b983;
  }
}

#mobile-nav {
  display: none;
}

.mobile-nav-button {
  height: 100%;
  width: 50%;
  float: left;
  border: 0;
  color: #fff;
  background-color: #bba58e;
  border-bottom: 2px solid #e7e7e7;
}

.mobile-active {
  border-bottom: 2px solid #6b553b !important;
}

.mobile-nav-button:focus,
.mobile-nav-button:active {
  outline: none;
}

.mobile-active {
  border-bottom: 2px solid #6b553b !important;
}

@media screen and (max-width: 899px),
screen and (max-height: 500px) {
  #mobile-nav {
    display: block;
    position: fixed;
    z-index: 100;
    top: 51px;
    right: 0;
    width: 100%;
    height: 32px;
  }

  [dir='rtl'] .navbar-toggle {
    float: right !important;
  }

  [dir='rtl'] .navbar-right,
  [dir='rtl'] .navbar-nav {
    float: unset !important;
  }

  [dir='rtl'] #navbar {
    margin-top: 50px;
  }

  .desktop-fullscreen {
    display: none;
  }

  .text-fullscreen {
    display: none;
  }
}

@media screen and (min-width: 899px),
screen and (max-height: 500px) {
  .mobile-fullscreen {
    display: none;
  }
}

</style>
<style lang="scss">
/* Override Dropdown CSS */
.p-dropdown-panel {
  background: #7e6951 !important;
  border-color: #705536 !important;
}

.p-dropdown-trigger {
  color: #f5f5f5 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 1rem;
}

.p-dropdown-items {
  margin-bottom: 0px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: rgba(158, 173, 230, 0.3) !important;
  color: #f5f5f5 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: hwb(228deg 56% 16% / 20%) !important;
}
</style>
